import { AccredibleInputErrorModule } from '@accredible-frontend-v2/forms/input-error';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { AccredibleSelectInputComponent } from './select-input.component';

const MATERIAL_MODULES = [MatSelectModule, MatIconModule, MatInputModule];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, AccredibleInputErrorModule, ...MATERIAL_MODULES],
  exports: [AccredibleSelectInputComponent],
  declarations: [AccredibleSelectInputComponent],
  providers: [],
})
export class AccredibleSelectInputComponentModule {}

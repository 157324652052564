import { AccredibleBaseInputInterface } from '@accredible-frontend-v2/forms/utils';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AccredibleKeyboardFocusService } from '@accredible-frontend-v2/services/keyboard-focus';

const TYPE_DEFAULT_VALUE = 'text';
const AUTOCOMPLETE_DEFAULT_VALUE = 'off';

@Component({
  selector:
    'accredible-text-input, accredible-text-input[adapt-width], accredible-text-input[no-field-infix-border]',
  templateUrl: './text-input.component.html',
  styleUrls: [`./text-input.component.scss`],
  // "ViewEncapsulation.None" is used to avoid using ::ng-deep when we have to make exceptions on the styles for this component.
  encapsulation: ViewEncapsulation.None,
  // We are not using ChangeDetectionStrategy.OnPush because the material input component
  // does not detect changes on the formControlRef (invalid, dirty or touched)
  // and errors do not get triggered with formGroup.markAllAsTouched() alone
})
export class AccredibleTextInputComponent
  implements AccredibleBaseInputInterface, AfterViewInit, OnDestroy
{
  @Input()
  formControlRef: UntypedFormControl;
  @Input()
  label = '';
  @Input()
  hint: string;
  @Input()
  legacyHint: string;
  @Input()
  placeholder: string = null; // Has to be initialized as null, otherwise the textarea placeholder appears as "undefined"
  @Input()
  required = false;
  @Input()
  type = TYPE_DEFAULT_VALUE;
  @Input()
  maxLength: number;
  @Input()
  autoComplete = AUTOCOMPLETE_DEFAULT_VALUE;
  @Input()
  readonly = false;
  @Input()
  icon: string;
  @Input()
  searchable = false;
  @Input()
  noError = false;
  @Input()
  textAreaMinHeight = 90;
  @Input()
  ariaLabel: string;
  @Input()
  dataCy: string;
  @ViewChild('textInput')
  textInputEl: ElementRef<HTMLElement>;
  @ViewChild('textareaInput')
  textareaInputEl: ElementRef<HTMLElement>;

  constructor(private readonly _keyboardFocus: AccredibleKeyboardFocusService) {}

  ngAfterViewInit(): void {
    if (this.textInputEl) {
      this._keyboardFocus.startFocusMonitoring(this.textInputEl);
    } else if (this.textareaInputEl) {
      this._keyboardFocus.stopFocusMonitoring(this.textareaInputEl);
    }
  }

  ngOnDestroy(): void {
    if (this.textInputEl) {
      this._keyboardFocus.stopFocusMonitoring(this.textInputEl);
    } else if (this.textareaInputEl) {
      this._keyboardFocus.stopFocusMonitoring(this.textareaInputEl);
    }
  }
}

<mat-form-field [floatLabel]="'always'"
  class="select-input"
  [class.native-select-input]="useNativeSelect"
  [class.hide-label]="hideLabel"
  [attr.aria-label]="ariaLabel">

  <mat-label [class.sr-only]="hideLabel">{{ label }}</mat-label>

  <mat-icon *ngIf="!!icon"
    class="icon"
    [ngClass]="icon">
  </mat-icon>

  <select *ngIf="useNativeSelect; else matSelect"
    matNativeControl
    [formControl]="formControlRef"
    [required]="required"
    [class.has-icon]="!!icon">
    <option *ngFor="let option of (dynamicLabel ? optionsWithDynamicLabel : options)"
      [value]="option.value ? option.value : option.name"
      [attr.aria-selected]="formControlRef?.value === (option.value ? option.value : option.name)">
      {{ dynamicLabel ? (option.name | async) : option.name }}
    </option>
  </select>

  <ng-template #matSelect>
    <mat-select [formControl]="formControlRef"
      [required]="required"
      [class.has-icon]="!!icon">
      <mat-option *ngFor="let option of (dynamicLabel ? optionsWithDynamicLabel : options)"
        [value]="option.value ? option.value : option.name">
        {{ dynamicLabel ? (option.name | async) : option.name }}
      </mat-option>
    </mat-select>
  </ng-template>

  <mat-error *ngIf="!noErrorMargin">
    <accredible-input-error>
      <ng-content></ng-content>
    </accredible-input-error>
  </mat-error>

</mat-form-field>

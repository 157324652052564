import { AccredibleBaseInputInterface } from '@accredible-frontend-v2/forms/utils';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

export interface AccredibleSelectInputOption {
  name: string;
  value?: unknown;

  [key: string]: unknown;
}

export interface AccredibleSelectInputOptionWithDynamicLabel {
  name: Observable<string>;
  value: unknown;
}

@Component({
  selector: 'accredible-select-input, accredible-select-input[adapt-width]',
  templateUrl: './select-input.component.html',
  styleUrls: [`./select-input.component.scss`],
  // "ViewEncapsulation.None" is used to avoid using ::ng-deep when we have to make exceptions on the styles for this component.
  encapsulation: ViewEncapsulation.None,
  // We are not using ChangeDetectionStrategy.OnPush because the material input component
  // does not detect changes on the formControlRef (invalid, dirty or touched)
  // and errors do not get triggered with formGroup.markAllAsTouched() alone
})
export class AccredibleSelectInputComponent implements AccredibleBaseInputInterface {
  @Input()
  formControlRef: UntypedFormControl;
  @Input()
  label: string;
  @Input()
  hideLabel = false;
  @Input()
  dynamicLabel = false;
  @Input()
  required = false;
  @Input()
  options: AccredibleSelectInputOption[];
  @Input()
  optionsWithDynamicLabel: AccredibleSelectInputOptionWithDynamicLabel[];
  @Input()
  icon: string;
  @Input()
  noErrorMargin = false;
  @Input()
  ariaLabel: string;
  @Input()
  useNativeSelect = false;
}
